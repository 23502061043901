@import url('./__nav/NavBarMovie__nav.css');
@import url('./__link/NavBarMovie__link.css');
@import url('./__btn/NavBarMovie__btn.css');
@import url('./__active/NavBarMovie__active.css');

.NavBarMovie {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media (max-width: 768px) {
  .NavBarMovie {
    display: none;
  }
}


