.MoviesCardList__more {
  display: flex;
  margin: 0 auto;
  min-height: 175px;
  justify-content: center;
  align-items: center;
}


@media (max-width: 768px) {
  .MoviesCardList__more {
    min-height: 115px;
  }
}

@media (max-width: 375px) {
  .MoviesCardList__more {
    min-height: 135px;
  }
}
