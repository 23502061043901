@media (max-width: 768px) {
  .MobileMenu__nav {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    background-color: #202020;
    position: fixed;
    top: 0;
    left: 247px;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
}

@media (max-width: 767px) {
  .MobileMenu__nav {
    left: 123px;
  }
}

@media (max-width: 375px) {
  .MobileMenu__nav {
    left: 0;
  }
}
