@media (max-width: 768px) {
  .MobileMenu__list {
    list-style: none;
    margin: 160px 0 0 0;
    padding: 0;
  }
}


@media (max-width: 375px) {
  .MobileMenu__list {
    margin: 145px 0 0 0;
  }
}
