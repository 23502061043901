.MobileMenu__acc {
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 0.75rem;
  color: #fff;
  text-decoration: none;
  max-width: 79px;
  margin: 0 auto 136px;
}
