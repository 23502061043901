.MobileMenu__mobile-btn {
  right: 2px;
  display: none;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  z-index: 3;
  background-color: transparent;
  border: none;
  outline: none;
  width: 40px;
  height: 22px;
  cursor: pointer;
}

.MobileMenu__mobile-btn span {
  height: 2px;
  width: 100%;
  transform: scale(1);
  background-color: #fff;
}

.MobileMenu__mobile-btn::before, .MobileMenu__mobile-btn::after {
  content: '';
  position: absolute;
  height: 2px;
  width: 70%;
  background-color: #f6f6f6;
  transition: all 0.3s ease 0s;
}

.MobileMenu__mobile-btn::before {
  top: 0;
}

.MobileMenu__mobile-btn::after {
  bottom: 0;
}

.MobileMenu__mobile-btn.active span {
  transform: scale(0);
}

.MobileMenu__mobile-btn.active::before {
  width: 22px;
  right: -5px;
  top: 50%;
  transform: rotate(-45deg) translate(0, -20%);
}

.MobileMenu__mobile-btn.active::after {
  width: 22px;
  right: -5px;
  bottom: 50%;
  transform: rotate(45deg) translate(1px, 70%);
}


@media (max-width: 768px) {
  .MobileMenu__mobile-btn {
    display: flex;
  }

  .MobileMenu__mobile-btn.active {
    position: fixed;
    right: 32px;
    top: 26px;
  }
}

@media (max-width: 375px) {
  .MobileMenu__mobile-btn {
    width: 34px;
    height: 20px;
  }

  .MobileMenu__mobile-btn.active {
    position: fixed;
    right: 17px;
    top: 28px;
  }


  .MobileMenu__mobile-btn::before, .MobileMenu__mobile-btn::after {
    width: 22px;
  }

  .MobileMenu__mobile-btn.active::before {
    top: 0;
    right: 0;
    transform: rotate(-45deg) translate(-1px, -50%);
  }

  .MobileMenu__mobile-btn.active::after {
    top: 0;
    right: 0;
    transform: rotate(45deg) translate(-1px, 30%);
  }


}



