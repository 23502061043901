.NavBar__link_btn {
  padding: 8px 20px;
  background-color: #3ddc84;
  border-radius: 3px;
  color: #000;
}

@media (max-width: 375px) {
  .NavBar__link_btn {
    padding: 5px 12px;
    font-size: 0.625rem;
  }
}
