.Footer__title {
  color: #8B8B8B;
  text-align: center;
  font-size: 0.8125rem;
  font-weight: normal;
  line-height: normal;
  border-bottom: 1px solid var(--line-light);
  padding-bottom: 20px;
  margin-top: 78px;
}


@media (max-width: 768px) {
  .Footer__title {
    margin-top: 133px;
  }
}

@media (max-width: 425px) {
  .Footer__title {
    font-size: 0.75rem;
    margin-top: 112px;
  }
}
