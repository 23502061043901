.SearchForm__input {
  box-sizing: border-box;
  min-height: 100%;
  border: none;
  flex-basis: 824px;
  background-color: inherit;
  padding-left: 63px;
  background: url(../../../images/icon-search.svg) no-repeat center left 25px;
  color: #8B8B8B;
  font-size: 1.125rem;
  font-weight: normal;
  line-height: 1.25rem;
  border-bottom-left-radius: 9px;

}

.SearchForm__input:focus {
  border-bottom: 1px solid var(--line-light);
  outline: none;
}


@media (max-width: 768px) {
  .SearchForm__input {
    flex-basis: 768px;
    max-width: 100%;
  }
}

@media (max-width: 660px) {
  .SearchForm__input {
    max-width: calc(80%);
  }
}

@media (max-width: 320px) {
  .SearchForm__input {
    max-width: 202px;
    font-size: 0.875rem;
    padding-left: 0;
    background: none;
    border-bottom-left-radius: 0;
  }
}
