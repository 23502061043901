.Footer__nav {
  display: flex;
}

@media (max-width: 425px) {
  .Footer__nav {
    flex-direction: column;
    align-items: center;
  }
}
