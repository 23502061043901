.SearchForm__slider-btn {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #343434;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.SearchForm__slider-btn:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 1px;
  bottom: 2px;
  background-color: #A0A0A0;
  border: 1px solid #fff;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}



