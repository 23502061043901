.Footer__link {
  color: #fff;
  text-decoration: none;
  text-align: right;
  font-size: 0.8125rem;
  font-weight: normal;
  line-height: normal;
  transition: opacity 0.7s ease;
}

.Footer__link:hover {
  opacity: 0.7;
}

.Footer__link:last-child {
  margin-left: 20px;
}

@media (max-width: 425px) {
  .Footer__link {
    font-size: 0.75rem;
  }

  .Footer__link:last-child {
    margin: 12px 0 0;
  }

}

