@media (max-width: 768px) {
  .MobileMenu__wrap {
    position: fixed;
    background: rgba(0, 0, 0, .3);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: burgerAnimation 0.4s;
    z-index: 1;
    will-change: opacity;
  }
}

.MobileMenu__wrap_none {
  display: none;
}

@keyframes burgerAnimation {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

