.Promo__title {
  font-size: 4rem;
  line-height: 3.25rem;
  text-align: center;
  max-width: 100%;
  font-weight: normal;
  color: #3ddc84;
  margin: 20% auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Promo__title_img {
  margin-right: 10px;
}


@media (min-width: 769px) and (max-width: 1150px) {
  .Promo__title {
    transition: all ease 0.5s;
    text-align: center;
    font-size: 3rem;
    max-width: calc(100% - 30px * 2);
  }
}

@media (max-width: 768px) {
  .Promo__title {
    margin: 30% auto 0;
    font-size: 3rem;
    width: 93.75vw;
    text-align: center;
  }
}

@media (max-width: 425px) {
  .Promo__title {
    margin: 50% auto 0;
    max-width: calc(100% - 14px * 2);
    font-size: 1.7375rem;
    line-height: 2.4375rem;
    letter-spacing: -0.0775rem;
  }

  .Promo__title_img {
    margin-right: 5px;
    width: 24px;
    height: 24px;
  }
}

@media (max-width: 375px) {
  .Promo__title_img {
    display: none;
  }
}

@media (max-width: 320px) {
  .Promo__title {
    font-size: 1.9375rem;

  }

}

