.NavBarMovie__link {
  color: #fff;
  text-decoration: none;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.125rem;
  transition: opacity 0.7s ease;
  margin-right: 3px;
}

.NavBarMovie__link:hover {
  opacity: 0.7;
}

.NavBarMovie__link:first-child {
  margin-right: 17px;
}
