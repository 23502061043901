.FormList__btn {
  font-size: 0.875rem;
  font-weight: 500;
  margin-top: 112px;
  width: 100%;
  min-height: 45px;
  color: #fff;
  border: none;
  background-color: var(--color-btn);
  cursor: pointer;
  transition: opacity 0.7s ease;
  border-radius: 3px;
}

.FormList__btn_disabled {
  font-size: 0.875rem;
  font-weight: 500;
  margin-top: 112px;
  width: 100%;
  min-height: 45px;
  color: #fff;
  border: none;
  background-color: var(--color-btn-disabled);
  cursor: auto;
  transition: all 0.7s ease;
  border-radius: 3px;
  opacity: 0.8;
}


.FormList__btn:hover {
  opacity: 0.7;
}

@media (max-width: 425px) {
  .FormList__btn {
    margin-top: 220px;
    font-size: 0.75rem;
  }
}
