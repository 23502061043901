.Footer__wrap {
  width: 93.75vw;
  max-width: 1140px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .Footer__wrap {
    max-width: calc(100% - 30px * 2);
  }
}


@media (max-width: 425px) {
  .Footer__wrap {
    max-width: calc(100% - 14px * 2);
  }
}
