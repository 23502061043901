.Header {
  display: flex;
  margin: 16px auto 18px;
  max-width: 1140px;
  width: 93.75vw;
}

@media (max-width: 1024px) {
  .Header {
    transition: all ease 0.4s;
    max-width: calc(100% - 30px * 2);
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 375px) {
  .Header {
    max-width: calc(100% - 14px * 2);
  }
}
