@import url('./__mobile-btn/MobileMenu__mobile-btn.css');
@import url('./__wrap/MobileMenu__wrap.css');
@import url('./__nav/MobileMenu__nav.css');
@import url('./__list/MobileMenu__list.css');
@import url('./__link/MobileMenu__link.css');
@import url('./__btn/MobileMenu__btn.css');
@import url('./__item/MobileMenu__item.css');
@import url('./__active/MobileMenu__active.css');
@import url('./__acc/MobileMenu__acc.css');

.MobileMenu {
  display: none;
}


@media (max-width: 768px) {
  .MobileMenu {
    box-sizing: border-box;
    display: flex;
  }
}
