.FormList__txt {
  color: #8B8B8B;
  text-align: center;
  font-size: 0.875rem;
  font-weight: normal;
  margin-top: 17px;
}

@media (max-width: 425px) {
  .FormList__txt {
    margin-top: 14px;
    font-size: 0.75rem;
  }
}
