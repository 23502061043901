.Promo__wrap {
  display: flex;
  flex-direction: column;
  width: 93.75vw;
  height: calc(100vh - 127px);
  margin: 0 auto;
  padding: 127px 0 0;
  max-width: 1140px;
  background-image: url(../../../images/landing-logo.svg);
  background-repeat: no-repeat;
  background-position: center top 40%;
}

@media (max-width: 1024px) {
  .Promo__wrap {
    transition: all ease 0.4s;
  }
}

@media (max-width: 768px) {
  .Promo__wrap {
    background-size: 680px 230px;
    background-position: center top 45%;
  }
}

@media (max-width: 425px) {
  .Promo__wrap {
    width: 100%;
    min-height: 580px;
    background-size: 680px 230px;
  }
}

@media (max-width: 375px) {
  .Promo__wrap {
    width: 100%;
    min-height: 580px;
  }
}

