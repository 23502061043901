.NotFound__wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 244px;
  min-height: 396px;
  transition: margin-top ease 0.7s;
}

@media (max-width: 768px) {
  .NotFound__wrap {
    margin-top: 407px;
  }
}

@media (max-width: 425px) {
  .NotFound__wrap {
    margin-top: 329px;
    min-height: 421px;
  }
}
