.FormList__err {
  margin-top: 15px;
  text-align: center;
  font-size: 0.85rem;
  min-height: 20px;
  font-weight: 500;
  color: #ee3465;
  margin-bottom: -15px;
  transition: opacity 0.7s ease;
}

.FormList__err_success {
  color: #2BE080;
  margin-top: 15px;
  text-align: center;
  font-size: 0.85rem;
  min-height: 20px;
  font-weight: 500;
  margin-bottom: -15px;
  transition: opacity 0.7s ease;
}

