.MoviesCard__desc {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--line-light);
  min-height: 58px;
  padding-top: 14px;
}

@media (max-width: 375px) {
  .MoviesCard__desc {
    min-height: 60px;
  }
}

