.MoviesCardList__grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 36px 20px;
  list-style: none;
  padding: 81px 0 0;
  margin: 0;

}


@media (max-width: 1024px) {
  .MoviesCardList__grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 28px 30px;
  }
}

@media (max-width: 425px) {
  .MoviesCardList__grid {
    grid-template-columns: 1fr;
    padding: 49px 0 0;
    gap: 29px 30px;
  }
}
