.MoviesCard__img {
  aspect-ratio: 1 / 1;
  display: block;
  object-fit: cover;
  cursor: pointer;
  background-color: var(--bg);
  height: 151px;
  width: 100%;
}

@media (max-width: 768px) {
  .MoviesCard__img {
    height: 190px;
  }
}

@media (max-width: 425px) {
  .MoviesCard__img {
    height: 168px;
  }
}
