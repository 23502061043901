.NotFound__description {
  margin-top: 5px;
  flex: 1;
  text-align: center;
  font-size: 1rem;
  font-weight: normal;
}

@media (max-width: 425px) {
  .NotFound__description {
    font-size: 0.75rem;
    margin-top: 10px;
  }
}
