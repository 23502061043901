.MoviesCardList__btn-more {
  margin-bottom: 28px;
  width: 320px;
  height: 36px;
  background-color: #2F2F2F;
  border: 0;
  outline: none;
  color: #fff;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  transition: opacity 0.7s ease;
  border-radius: 6px;
}

.MoviesCardList__btn-more:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .MoviesCardList__btn-more {
    margin-top: 34px;
    margin-bottom: 0;
  }
}

@media (max-width: 375px) {
  .MoviesCardList__btn-more {
    margin-top: 10px;
    width: 240px;
  }
}

